import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['collapsable', 'collapsableControl']

  connect() {
    super.connect();
    this.hideFilters();
  }

  hideFilters() {
    const media = '(max-width: 768px)';
    const mediaQuery = window.matchMedia(media);
    if (mediaQuery.matches) {
      const collapsable = this.collapsableTarget;
      const collapsableControl = this.collapsableControlTarget;
      collapsable.classList.remove('show');
      collapsableControl.setAttribute('aria-expanded', 'false');
    }
  }

  addLoadingSpinner() {
    const spinner = '<span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span> Cargando...';
    const gridMangas = document.getElementById('gridMangas');
    gridMangas.innerHTML = spinner;
  }

  onSubmit() {
    this.hideFilters();
    this.addLoadingSpinner();
    window.scrollTo(0, 0);
  }
}
