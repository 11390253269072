import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'sidenav']

  open(element) {
    const sidenav = element;
    sidenav.style.width = '100%';
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    sidenav.setAttribute('aria-opened', 'true');
  }

  close(element) {
    const sidenav = element;
    sidenav.style.width = '0%';
    document.body.style.overflow = 'auto';
    document.body.style.height = 'auto';
    sidenav.setAttribute('aria-opened', 'false');
  }

  toggleSidenav() {
    const sidenav = document.getElementById('sidenav');
    const isOpened = sidenav.getAttribute('aria-opened') === 'true';
    if (isOpened) {
      this.close(sidenav);
    } else {
      this.open(sidenav);
    }
  }

  toggleDropdown(event) {
    const button = event.currentTarget;
    button.classList.toggle('active');
    const dropdownContent = button.nextElementSibling;
    if (dropdownContent.style.display === 'block') {
      dropdownContent.style.display = 'none';
    } else {
      dropdownContent.style.display = 'block';
    }
  }
}
