import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = ['couponCodeInput', 'spinnableButton'];

  connect() {
    super.connect();
  }

  beforeReflex() {
    const element = this.spinnableButtonTarget;

    const spinner = '<div class="spinner-border spinner-border-sm text-secondary" role="status">'
      + '<span class="visually-hidden">Loading...</span>'
      + '</div>';
    element.innerHTML = spinner;
    element.disabled = true;
  }

  addCouponCodeToCart() {
    const couponCode = this.couponCodeInputTarget.value;

    this.stimulate('ShoppingCartReflex#add_coupon_code_to_cart', couponCode);
  }
}
