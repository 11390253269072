import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['ul']

  connect() {
    super.connect();
    this.closeMainDropdown();
  }

  closeMainDropdown() {
    const ul = this.ulTarget;
    const parent = ul.closest('.nav-item.dropdown');
    parent.addEventListener('hidden.bs.dropdown', () => {
      const openedSubmenu = parent.querySelector('.dropdown-submenu .show');
      if (openedSubmenu) {
        openedSubmenu.classList.remove('show');
      }
    });
  }

  open(e) {
    e.preventDefault();
    e.stopPropagation();
    const li = e.currentTarget;
    const ul = this.ulTarget;
    const parentDropdown = li.closest('.dropdown-menu');
    const openedSubmenu = parentDropdown.querySelector('.dropdown-menu .show');
    ul.classList.toggle('show');
    if (openedSubmenu) {
      openedSubmenu.classList.remove('show');
    }
  }
}
