import ApplicationController from '../application_controller';
import shakeBadges from '../../lib/shake_badges';

export default class extends ApplicationController {
  static targets = ['quantitySelector', 'spinnableButton'];

  connect() {
    super.connect();
  }

  beforeReflex() {
    const element = this.spinnableButtonTarget;

    if (!element) { return; }

    const spinner = '<div class="spinner-border spinner-border-sm text-secondary" role="status">'
      + '<span class="visually-hidden">Loading...</span>'
      + '</div>';
    element.innerHTML = spinner;
    element.disabled = true;
  }

  finalizeReflex() {
    shakeBadges();
  }

  getProductId(element) {
    return element.getAttribute('data-product-id');
  }

  addProductToCartFromButton() {
    const quantity = 1;

    this.stimulate('ShoppingCartReflex#add_products_to_cart', quantity);
  }

  addProductToCartFromSelector() {
    const selector = this.quantitySelectorTarget;
    const quantity = parseInt(selector.value, 10);

    this.stimulate('ShoppingCartReflex#products_on_cart', quantity);
  }

  addProdutToCartFromSelectorWithButton() {
    const selector = this.quantitySelectorTarget;
    const quantity = parseInt(selector.value, 10);

    this.stimulate('ShoppingCartReflex#add_products_to_cart', quantity);
  }

  removeProductFromCart(event) {
    const element = event.target;
    const productRow = element.closest('.product-row');
    productRow.classList.add('animate__animated', 'animate__fadeOutLeftBig');

    productRow.addEventListener('animationstart', () => {
      this.stimulate('ShoppingCartReflex#remove_product_from_cart');
    });
  }
}
