import $ from 'jquery';
import select2Es from 'select2/src/js/select2/i18n/es';
import ApplicationController from './application_controller';
import 'select2';

require('../lib/rut');

export default class extends ApplicationController {
  static targets = [
    'form', 'rutField', 'regionSelect', 'communeSelect', 'streetNameSelect', 'streetNumberInput', 'phoneField',
    'toAddressRadioButton', 'inPlaceRadioButton', 'addressSuplementInput', 'addressObservationInput',
  ];

  static values = {
    toAddress: Boolean,
    inPlace: Boolean,
    // communeId: Number,
    defaults: Boolean,
    userData: Object,
  };

  connect() {
    super.connect();

    this.requiredToAddressInputs = [
      this.regionSelectTarget,
      this.communeSelectTarget,
      this.streetNameSelectTarget,
      this.streetNumberInputTarget,
    ];

    this.optionalToAddressInptus = [
      this.addressSuplementInputTarget,
      this.addressObservationInputTarget,
    ];

    this.initAllSelect2();
    // eslint-disable-next-line func-names
    $('.select2, .ajax-select2').on('select2:select', function () {
      const event = new Event('change', { bubbles: true }); // Fire native event
      this.dispatchEvent(event);
    });

    this.initFormatRut();
    this.initValidations();
    this.loadDefaultData();
    this.checkEnableds();
    this.clearAllSelect2();
    this.initRadiosListener();
    this.hideToAddressInputs();
  }

  initToAddressListener() {
    const toAddress = this.toAddressRadioButtonTarget;

    toAddress.addEventListener('change', (event) => {
      this.toAddressValue = event.currentTarget.checked;

      if (this.hasInPlaceRadioButtonTarget) {
        this.inPlaceValue = !event.currentTarget.checked;
      }

      if (this.toAddressValue) {
        const communeId = this.communeSelectTarget.value;
        this.showToAddressInputs();
        this.stimulate('OrdersFormReflex#estimate_delivery', communeId);
      }
    });
  }

  initInPlaceListener() {
    const inPlace = this.inPlaceRadioButtonTarget;

    inPlace.addEventListener('change', (event) => {
      this.inPlaceValue = event.currentTarget.checked;
      this.toAddressValue = !event.currentTarget.checked;

      if (this.inPlaceValue) {
        this.hideToAddressInputs();
        this.stimulate('OrdersFormReflex#show_in_place_order');
      }
    });
  }

  initRadiosListener() {
    this.initToAddressListener();

    if (this.hasInPlaceRadioButtonTarget) {
      this.initInPlaceListener();
    }
  }

  initAllSelect2() {
    const that = this;

    $(this.regionSelectTarget).select2({
      language: select2Es,
      theme: 'bootstrap-5',
    });

    $(this.communeSelectTarget).select2({
      language: select2Es,
      theme: 'bootstrap-5',
    });

    $(this.streetNameSelectTarget).select2({
      language: select2Es,
      theme: 'bootstrap-5',
      ajax: {
        url: '/locations/street_names',
        data(params) {
          const query = {
            street_name: params.term,
            commune_id: that.communeSelectTarget.value,
          };
          return query;
        },
        processResults(data, params) {
          let newValue = '';
          if (data.length === 0) {
            newValue = [
              {
                id: params.term.toUpperCase(),
                text: params.term.toUpperCase(),
              },
            ];
          } else {
            newValue = data;
          }

          return {
            results: newValue,
          };
        },
        cache: true,
      },
      placeholder: 'Busca tu calle',
      minimumInputLength: 3,
    });
  }

  loadDefaultData() {
    if (this.defaultsValue) {
      const userData = this.userDataValue;
      this.showLoading(true);
      this.communeSelectTarget.disabled = false;
      this.streetNameSelectTarget.disabled = false;
      this.streetNumberInputTarget.disabled = false;
      $(this.regionSelectTarget).val(userData.address_region_id).trigger('change');
      $(this.communeSelectTarget).val(userData.address_commune_id).trigger('change');
      const streetName = userData.address_street_name;
      const newOption = new Option(streetName, streetName, false, false);
      $(this.streetNameSelectTarget).append(newOption).trigger('change');
      $(this.streetNameSelectTarget).val(streetName).trigger('change');
      this.showLoading(false);
    } else {
      this.clearAllSelect2();
    }
  }

  selectRegion(event) {
    const element = event.target;
    const regionId = parseInt(element.value, 10);
    this.fillCommunes(regionId);
    this.checkEnableds();
  }

  async fillCommunes(regionId) {
    this.showLoading(true);
    const communeSelect = this.communeSelectTarget;
    this.resetCommuneSelect();
    this.resetStreetNameSelect();
    // this.resetStreetNumberSelect();

    const url = '/locations/communes?';
    const query = new URLSearchParams({
      region_id: regionId,
    });

    const response = await fetch(url + query);
    const data = await response.json();

    data.forEach((commune) => {
      const newOption = new Option(commune.name, commune.id, false, false);
      communeSelect.append(newOption);
    });
    this.showLoading(false);
  }

  selectCommune(event) {
    const element = event.target;
    const communeId = element.value;
    this.stimulate('OrdersFormReflex#estimate_delivery', communeId);
    this.resetStreetNameSelect();
    // this.resetStreetNumberSelect();
    // this.initStreetNameSelect(communeId);
  }

  selectStreetName(event) {
    // const element = event.target;
    // const streetChilexrpessId = $(element).select2('data')[0].chilexpress_id;
    // this.resetStreetNumberSelect();
    this.checkEnableds();
    // this.initStreetNumberSelect(streetChilexrpessId);
  }

  resetSelect(target, defaultOption) {
    const select = target;
    select.length = 0;
    const newOption = new Option(defaultOption, '', false, false);
    select.append(newOption);
  }

  showLoading(boolean) {
    const loading = document.getElementById('loading');
    if (boolean) {
      loading.classList.remove('d-none');
    } else {
      loading.classList.add('d-none');
    }
  }

  showAlert(kind, message) {
    const div = document.createElement('div');
    div.className = kind;
    div.setAttribute('role', 'alert');
    div.id = 'bootstrap-alert';
    const text = document.createTextNode(message);
    div.appendChild(text);

    const main = document.getElementById('main');
    main.insertBefore(div, main.firstElementChild);
  }

  clearAlert() {
    const alert = document.getElementById('bootstrap-alert');
    if (alert) { alert.remove(); }
  }

  checkEnableds() {
    this.clearAlert();
    this.communeSelectTarget.disabled = !this.regionSelectTarget.value;
    this.streetNameSelectTarget.disabled = !this.communeSelectTarget.value;
    this.streetNumberInputTarget.disabled = !this.streetNameSelectTarget.value;
    // this.streetNumberSelectTarget.disabled = !this.streetNameSelectTarget.value;
  }

  // Select2
  initStreetNameSelect(communeId) {
    const select = this.streetNameSelectTarget;

    $(select).on('select2:select', (e) => {
      const { data } = e.params;
      const { id } = data;
      const chilexpressId = data.chilexpress_id;

      document.querySelector(`select option[value="${id}"]`).setAttribute('data-chilexpress-id', chilexpressId);
      $(select).trigger('change');
    });
  }

  // initStreetNumberSelect(streetNameId) {
  //   const select = this.streetNumberSelectTarget;

  //   $(select).select2({
  //     language: select2Es,
  //     theme: 'bootstrap-5',
  //     ajax: {
  //       url: '/locations/street_numbers',
  //       data(params) {
  //         const query = {
  //           street_id: streetNameId,
  //           street_number: params.term,
  //         };
  //         return query;
  //       },
  //       processResults(data) {
  //         return {
  //           results: data,
  //         };
  //       },
  //       cache: true,
  //     },
  //     placeholder: 'Busca tu número',
  //     minimumInputLength: 1,
  //   });
  // }

  // Callbacks
  beforeEstimateDelivery() {
    this.showLoading(true);
  }

  estimateDeliverySuccess() {
    this.showLoading(false);
    this.checkEnableds();
  }

  estimateDeliveryError(element, reflex, error) {
    this.showLoading(false);
    this.showAlert('alert alert-danger', error);
  }

  // Resets
  resetCommuneSelect() {
    this.resetSelect(this.communeSelectTarget, 'Selecciona tu Comuna');
  }

  resetStreetNameSelect() {
    this.resetSelect(this.streetNameSelectTarget, 'Selecciona tu Calle');
  }

  // resetStreetNumberSelect() {
  //   this.resetSelect(this.streetNumberSelectTarget, 'Selecciona tu Número');
  // }

  // Validations
  initValidations() {
    this.initValidateRut();
    this.initValidatePhone();
    this.initFormValidation();
  }

  initFormatRut() {
    $(this.rutFieldTarget).rut({
      formatOn: 'keyup',
      minimumLength: 8,
      validateOn: 'change',
    });
  }

  initValidateRut() {
    const rutField = this.rutFieldTarget;
    $(rutField)
      .on('rutValido', () => rutField.setCustomValidity(''))
      .on('rutInvalido', () => rutField.setCustomValidity('not valid'));
  }

  initValidatePhone() {
    const phoneField = this.phoneFieldTarget;
    phoneField.addEventListener('input', (e) => {
      const { value } = e.target;
      if (value.length === 9) {
        phoneField.setCustomValidity('');
      } else {
        phoneField.setCustomValidity('not valid');
      }
    });
  }

  initFormValidation() {
    const form = this.formTarget;
    form.addEventListener('submit', (event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: document.querySelectorAll('#new-order-form :invalid')[0].parentElement.offsetHeight,
        });
      } else {
        this.showLoading(true);
      }

      form.classList.add('was-validated');
    }, false);
  }

  // Select2 clearing

  clearAllSelect2() {
    $(document).on('turbolinks:before-cache', () => {
      this.clearSelect2(this.regionSelectTarget);
      this.clearSelect2(this.communeSelectTarget);
      this.clearSelect2(this.streetNameSelectTarget);
      // this.clearSelect2(this.streetNumberSelectTarget);
    });
  }

  clearSelect2(element) {
    const el = $(element);

    if (el.hasClass('select2-hidden-accessible')) {
      el.select2('destroy');
    }
  }

  toAddressOnChange() {
    if (this.toAddressValue) {
      this.showToAddressInputs();
      const communeId = this.communeSelectTarget.value;
      this.stimulate('OrdersFormReflex#estimate_delivery', communeId);
    } else {
      this.hideToAddressInputs();
      this.stimulate('OrdersFormReflex#estimate_delivery', null);
    }
  }

  showElement(element) {
    const input = element;
    const row = input.closest('.row');
    row.classList.remove('d-none');
    input.disabled = false;
  }

  hideElement(element) {
    const input = element;
    const row = input.closest('.row');
    row.classList.add('d-none');
    input.disabled = true;
  }

  showToAddressInputs() {
    this.requiredToAddressInputs.forEach((element) => {
      const input = element;
      this.showElement(input);
      input.required = true;
    });

    this.optionalToAddressInptus.forEach((element) => {
      const input = element;
      this.showElement(input);
    });
  }

  hideToAddressInputs() {
    const toAddress = this.toAddressRadioButtonTarget;

    if (!toAddress.checked) {
      this.requiredToAddressInputs.forEach((element) => {
        const input = element;
        this.hideElement(input);
        input.required = false;
      });

      this.optionalToAddressInptus.forEach((element) => {
        const input = element;
        this.hideElement(input);
      });
    }
  }
}
