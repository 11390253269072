import Splide from '@splidejs/splide';
import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  connect() {
    this.initHomeSlider();
    this.initProductsSlider();
    this.initOffersSlider();
    this.initFeaturedMangasSlider();
    this.initBestSellerMangasSlider();
    this.initAdditionalInfoSlider();
    this.clearSliders();
  }

  initProductsSlider() {
    const options = this.productOptions();

    const splide = new Splide('#last-added-products-splide', options);
    splide.mount();
  }

  initOffersSlider() {
    const options = this.productOptions();

    if (document.getElementById('offers-splide')) {
      const splide = new Splide('#offers-splide', options);
      splide.mount();
    }
  }

  initFeaturedMangasSlider() {
    const options = this.productOptions();

    if (document.getElementById('featured-mangas-splide')) {
      const splide = new Splide('#featured-mangas-splide', options);
      splide.mount();
    }
  }

  initBestSellerMangasSlider() {
    const options = this.productOptions();

    if (document.getElementById('best-seller-splide')) {
      const splide = new Splide('#best-seller-splide', options);
      splide.mount();
    }
  }

  initAdditionalInfoSlider() {
    const options = {
      perPage: 3,
      rewind: true,
      gap: '1.5rem',
      autoHeight: true,
      arrows: false,
      breakpoints: {
        576: {
          perPage: 1,
          arrows: false,
          padding: '2rem',
        },
        768: {
          perPage: 2,
          arrows: false,
          padding: '2rem',
        },
        992: {
          perPage: 3,
        },
      },
    };

    const splide = new Splide('#additional-info-splide', options);
    splide.mount();
  }

  productOptions() {
    const options = {
      perPage: 5,
      rewind: true,
      gap: '0.6rem',
      autoHeight: true,
      breakpoints: {
        576: {
          perPage: 2,
          arrows: false,
          padding: { left: '1rem', right: '1rem' },
        },
        768: {
          gap: '1.5rem',
          perPage: 3,
          arrows: false,
          padding: '2rem',
        },
        992: {
          gap: '1.5rem',
          perPage: 4,
        },
      },
    };

    return options;
  }

  initHomeSlider() {
    const options = {
      type: 'loop',
      autoplay: true,
      interval: 5000,
      speed: 1000,
    };
    const splide = new Splide('#home-splide', options);
    splide.mount();
    // new Splide('#home-splide', options).mount();
  }

  clearSliders() {
    document.addEventListener('turbolinks:before-cache', () => {
      this.clearSlider('last-added-products-splide');
      this.clearSlider('home-splide');
      this.clearSlider('offers-splide');
      this.clearSlider('last-added-products-splide');
      this.clearSlider('best-seller-splide');
      this.clearSlider('featured-mangas-splide');
    });
  }

  clearSlider(id) {
    // new Splide('#last-added-products-splide').destroy();
    const slider = document.getElementById(id);
    slider.parentElement.remove();
  }
}
