export default function shakeBadges() {
  const badges = document.getElementsByClassName('shopping-cart-badge');
  for (let i = 0; i < badges.length; i += 1) {
    const badge = badges[i];
    badge.classList.add('horizontal-shake');

    // Restart animation when class already exists
    const newBadge = badge.cloneNode(true);
    badge.parentNode.replaceChild(newBadge, badge);
  }
}
