import Splide from '@splidejs/splide';
import ApplicationController from '../application_controller';
import shakeBadges from '../../lib/shake_badges';

export default class extends ApplicationController {
  connect() {
    this.slideIndex = 0;
    this.initSilders();
  }

  initSilders() {
    this.initSuggestedProductsSlider();
    this.clearSliders();
  }

  reInitSilders() {
    shakeBadges();
    this.initSilders();
  }

  initSuggestedProductsSlider() {
    const options = this.productOptions();
    const that = this;
    const splide = new Splide('#suggested-products-splide', options);

    splide.on('move', (newIndex) => {
      that.slideIndex = newIndex;
    });

    splide.mount();
    // splide.Components.Controller.go(that.slideIndex);
    // splide.Components.Move.jump(this.slideIndex);
  }

  productOptions() {
    const options = {
      start: this.slideIndex,
      perPage: 5,
      rewind: true,
      gap: '0.6rem',
      autoHeight: true,
      breakpoints: {
        576: {
          perPage: 2,
          arrows: false,
          padding: { left: '1rem', right: '1rem' },
        },
        768: {
          gap: '1.5rem',
          perPage: 3,
          arrows: false,
          padding: '2rem',
        },
        992: {
          gap: '1.5rem',
          perPage: 4,
        },
      },
    };

    return options;
  }

  clearSliders() {
    document.addEventListener('turbolinks:before-cache', () => {
      this.clearSlider('suggested-products-splide');
    });
  }

  clearSlider(id) {
    const slider = document.getElementById(id);
    slider.parentElement.remove();
  }
}
