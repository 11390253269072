import { Controller } from 'stimulus';
import $ from 'jquery';

require('../../lib/jquery.easy-autocomplete');

export default class extends Controller {
  static targets = ['searchForm', 'searchInput'];

  connect() {
    super.connect();
    this.initAutocomplete();
  }

  initAutocomplete() {
    const that = this;

    const inputId = this.searchInputTarget.id;

    const options = {
      url(query) {
        return `/products/search.json?search=${query}`;
      },

      getValue: 'name',
      list: {
        onChooseEvent() {
          that.submitSearch();
        },
      },
    };

    $(`#${inputId}`).easyAutocomplete(options);
  }

  submitSearch() {
    const formId = this.searchFormTarget.id;
    const form = document.getElementById(formId);
    form.submit();
  }
}
