/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import 'controllers';
import $ from 'jquery';
import select2Es from 'select2/src/js/select2/i18n/es.js';
import Pagy from '../pagy/pagy';
import 'select2';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('bootstrap');
require('jquery');
require('trix');
require('@rails/actiontext');

document.addEventListener('turbolinks:load', () => {
  // Enable tooltip everywhere
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

  // Enable popover everywhere
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

  Pagy.init();
  window.Pagy = Pagy; // export to a window, Now it can be called from RAILS .js.erb as window.Pagy
});
